<template>
<Navbar />
<div class="corps mt-5  rounded border">
    <h2 class="text-center">Enregistrement d'une nouvelle formation</h2>
  <div class="container mt-2">
       <div class="form-group">
            <label for="titre">Titre <span class="text-danger">*</span></label>
            <input type="text" autofocus v-model="title" class="form-control" :class="getTitleClass" name="titre" id="titre">
            <span class="invalid-feedback">Veillez renseigner ce champ!</span>
          </div>
      <div class="row">
         
          <div class="col-4 form-group">
             <label for="duree">Durée <span class="text-danger">*</span></label>
             <input type="number" v-model="duree" id="duree" :class="getlastDurationClass"  name="duree" class="form-control">
               <span class="invalid-feedback">Durée invalide</span>
          </div>
          <div class="col-4 form-group">
              <label for="prix">Prix <span class="text-danger">*</span></label>
              <input type="number" v-model="price" class="form-control" :class="getPriceClass" name="prix" id="prix">
              <span class="invalid-feedback">Prix invalid</span>
          </div>
          <div class="col-4 form-group">
              <label for="monaie">Monaie <span class="text-danger">*</span></label>
              <select v-model="currency"  :class="getCurrencyClass" class="form-control" id="monaie">
                    <option value="" selected>...</option>
                    <option value="XAF">XAF</option>
                    <option value="XOF">XOF</option>
               </select>
               <span class="invalid-feedback">Chississez une monaie</span>
          </div>
      </div>
      <div class="row d-flex">
          <div class="col-6 form-group">
              <div class="" v-if="select=='1'">
                <label for="categorie" class="form-label">Catégorie <span class="text-danger">*</span></label>
                <select v-model="categorie" :class="getCategorieClass" class="form-control" id="categorie" >
                    <option value="">...</option>
                    <option :value="c.id" v-for="c in categories" :key="c.id"> {{c.name}}</option>
                </select>
                <span class="invalid-feedback" >Chississez une catégorie</span>
               </div>
               <div class="border p-2" v-if="select!='1'">
                   <label for="categorie" class="form-label">Catégorie <span class="text-danger">*</span></label>
                    <input v-model="inputCategorie.name" :class="getCategoryClass"  class="form-control" id="categorie" placeholder="Cathegorie..." />
                    <span class="invalid-feedback" >Taille invalide!</span>
                    <label for="desc-cath" class="form-label">Description de la cathegorie:</label>
                    <textarea v-model="inputCategorie.description" class="form-control" id="desc-cath" cols="30" rows="2"></textarea>
               </div>
          </div>
          <div class="col-6">
              <div class="form-check mt-4">
                <input class="form-check-input" value="1" v-model="select" type="radio" id="flexRadioDefault1">
                <label class="form-check-label" for="flexRadioDefault1">
                    Selectionner une cathegorie
                </label>
                </div>
                <div class="form-check">
                <input class="form-check-input" value="2" v-model="select" type="radio" id="flexRadioDefault2" >
                <label class="form-check-label" for="flexRadioDefault2">
                    Creer une nouvelle cathegorie
                </label>
                </div>
          </div>
      </div>
        <div class="form-group">
            <label class="form-label" for="description">Description</label>
            <input type="text" v-model="description" class="form-control" name="description" id="description">
        </div>
        <div class="form-group">
            <label for="user">Formateur:</label>
            <select name="user" v-model="user" id="user" class="form form-control">
                <option 
                    :value="u.id"
                    v-for="u in teachers" 
                    :key="u.id"
                    :selected="u.email==getLogedUseEmail"
                >{{u.firstName}} {{u.lastName}}</option>
            </select>
        </div>
    <div class="mb-3">
        <input 
            type="submit" 
            class="btn btn-primary" 
            @click.prevent="onSubmit"
            :disabled="title.length==0 || price<=0 || duree<=0 || currency=='' || (categorie=='' && inputCategorie.name=='')"
            value="Créer un cours">
    </div>
  </div>
</div>
</template>

<script>
import Navbar from '@/components/layouts/Navbar.vue'
export default {
    components: {
        Navbar
    },
    name:'Course',
    data(){
        return{
            select: '1',
            categories: [],
            title:'',
            price: 0,
            currency:'',
            duree: 0,
            description:'',
            categorie:'',
            inputCategorie: {name: '', description: ''},
            user:0,
            teachers:[],
        }
    },
    beforeMount(){
      this.$nextTick(function () {
          this.loadCategories()
          this.loadTeachers()
      })
    },
    methods:{
     async onSubmit(){
        const data={
            title:this.title,
            price: this.price,
            duration:this.duree,
            currency:this.currency,
            description:this.description,
            category:this.categorie,
            teacher: this.user
        }
        if(this.select!='1'){
            await this.$http.post('/courses/category', this.inputCategorie)
            .then(res=>{
                data.category = res.data.id
        })
        }
        await this.$http.post('/courses/create/', data )
        .then(response=>{ 
            this.$toast.success("Success")
            this.$router.push({name: 'ShowCourseDetails', params: { idCourse: response.data.id}})
        })
        .catch(err=>{
            this.$toast.error("An error occured! please try egain")
            console.log(err)
        })
     },
     async loadTeachers(){
        await this.$http.get('/auth/user/teacher-list/')
        .then(res=>{
            this.teachers=res.data
            console.log(res.data)
        })
     },
     async loadCategories(){
        await this.$http.get('courses/category')
        .then(res=>this.categories=res.data)
     }
    },
    computed:{
        getLogedUseEmail(){
            return this.$store.state.logedUser.email
        },
        getTitleClass(){
        if(this.title.length==0)
            return ''
         if(this.title.length<2){
             return 'is-invalid'
         } return ''
        },
         getCategoryClass(){
            if(this.select !='1' && this.inputCategorie.name.length<2 && this.inputCategorie.name.length>0){
                return 'is-invalid'
            } else if(this.inputCategorie.name.length==0) 
                return ''
            return 'is-valid'
        },
        getPriceClass(){
            if(this.price<0){
                return 'is-invalid' 
            }else{
                return ''
            }
        },
        getlastDurationClass(){
            if(this.duree<0){
                return 'is-invalid' 
            }else{
                return ''
            }
        },
        getCurrencyClass(){
            if(this.currency==''){
                return 'is-invalid' 
            }else{
                return ''
            }
            
        },
        getCategorieClass(){
            if(this.categorie==''){
                return 'is-invalid' 
            } return ''
            
        },
    }


}
</script>

<style  scoped>
.corps{
    width: 50%;
    margin: auto;
}

</style>